import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Hero from "../components/home/Hero"
import { graphql } from "gatsby"
// import PropertiesList from "../components/home/PropertiesList"
import SearchProperties from "../components/home/SearchProperties"
import PropertiesList from "../components/home/PropertiesList"
import SearchBar from "../components/search/SearchBar"

export default function IndexPage({ data }) {
  const [doSearch, setDoSearch] = React.useState(false)
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Ibiza High Life",
    url: "https://www.ibizahighlife.com/",
    logo: "https://www.ibizahighlife.com/icons/icon-512x512.png",
    // sameAs: "https://twitter.com/book_ibiza",
  }
  return (
    <Layout>
      <Seo title="Home" schemaMarkup={schema} />
      <div>
        <Hero />
        {/* <SearchBar setDoSearch={setDoSearch} /> */}
        {/* <SearchProperties doSearch={doSearch} /> */}
        <SearchBar
          className=" min-w-md px-2 lg:px-8  container mx-auto relative -mt-24"
          heading="Begin your search for your dream holiday home"
        />

        <PropertiesList properties={data.allSanityProperty.nodes} />
      </div>
    </Layout>
  )
}

// const SearchBar = ({ setDoSearch }) => (
//   <div>
//     <h2>Search</h2>
//     <button
//       onClick={() => {
//         setDoSearch(true)
//       }}
//     >
//       click
//     </button>
//   </div>
// )

export const query = graphql`
  query {
    allSanityProperty(
      filter: {
        collections: {
          elemMatch: { ref: { current: { eq: "home-collection" } } }
        }
      }
    ) {
      nodes {
        _id
        slug {
          current
        }
        name
        bathrooms
        bedrooms
        area
        description {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          list
          style
        }
        propertyFeatures {
          name
        }
        highlight
        images {
          asset {
            gatsbyImageData
          }
        }
        name
        lowPrice
        highPrice
      }
    }
  }
`
