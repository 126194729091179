import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Hero() {
  return (
    <div className="w-full mx-auto">
      <main className="container mx-auto grid justify-items-stretch items-stretch h-screen px-2 lg:px-8">
        <div className="col-start-1 row-start-1 relative z-10 self-auto">
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-serif uppercase text-center text-white w-11/12 lg:w-1/2 mx-auto mt-24">
            Curated Luxury Travel Experiences
          </h1>
        </div>
        <div className="col-start-1 row-start-1 relative z-0 self-auto">
          <StaticImage
            className="object-cover object-center h-[80vh] w-full"
            src="../../../static/img/ihl-hero2.png"
            alt="Ibiza High Life"
          />
        </div>
      </main>
    </div>
  )
}
