// import algoliasearch from "algoliasearch"
import React from "react"
import { Fragment } from "react"
import { connectHits, InstantSearch } from "react-instantsearch-core"
// import { RefinementList, SearchBox } from "react-instantsearch-dom"
import { getSearchClient } from "../../utils/algolia"
import Hit from "../search/Hit"
import SearchBar from "../search/SearchBar"

export default function SearchProperties({ doSearch }) {
  const searchClient = getSearchClient(true)

  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName="properties">
        <h2 className="container mx-auto flex px-4 md:px-10">
          Search for your dream holiday villa
        </h2>
        <SearchBar className="relative z-50 container mx-auto flex justify-center p-4 md:p-10" />
        <Properties />
      </InstantSearch>
    </div>
  )
}

const Properties = () => {
  return (
    <div className="container mx-auto py-6">
      <div className="col-span-12 md:col-span-9 lg:col-span-7 p-4 md:p-10">
        <CustomHits />
      </div>
    </div>
  )
}

const Hits = ({ hits }) => (
  <div className="grid gap-4 md:gap-10 ">
    {hits.length > 0 ? (
      <Fragment /> // <h3 className="text-xl font-light">{hits.length} results founds</h3>
    ) : (
      <div className="flex justify-center">
        <svg
          className="animate-spin h-12 w-12 text-black"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    )}
    <ol className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 ">
      {hits.map(
        hit =>
          hit && (
            <li key={hit.objectID}>
              {hit ? <Hit hit={hit} /> : <div>Loading</div>}
            </li>
          )
      )}
    </ol>
  </div>
)

const CustomHits = connectHits(Hits)
