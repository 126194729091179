import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { numberWithCommas } from "../../utils/formatUtil"

export default function PropertiesList({ properties }) {
  // console.log(properties)
  return (
    <div className="w-full max-auto">
      <div className="container mx-auto px-2 lg:px-8 pb-6">
        <h2 className="py-12">A selection of our finest villas</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 gap-4 sm:gap-8 md:gap-12 lg:gap-14 2xl:gap-20">
          {properties
            .filter(p => p.name)
            .map((property, key) => (
              <div
                key={key}
                className="bg-white hover:shadow-xl transition duration-300 ease-in-out ring-1 ring-gray-200"
              >
                <Link to={`/property/${property.slug.current}`}>
                  {property.images[0]?.asset?.gatsbyImageData && (
                    <GatsbyImage
                      image={property.images[0].asset.gatsbyImageData}
                      className="h-56 w-full"
                      alt={property.name}
                    />
                  )}
                  <div className="p-4">
                    <div className="font-bold flex items-center justify-between">
                      <div>
                        <h3 className="text-lg">{property.name}</h3>
                        <span className="text-gray-400 font-medium font-landing-sans">
                          {property.area}
                        </span>
                      </div>
                      <button className="text-4xl outline-none focus:outline-none">
                        ♡
                      </button>
                    </div>
                    <div className="py-3  h-32 overflow-hidden">
                      {/* <span className="bg-black text-white text-xs px-2 py-px font-medium rounded-lg">
                    Included
                  </span> */}
                      <div className="font-light font-landing-sans">
                        {property.highlight}
                      </div>
                      <div className="grid grid-cols-2 items-center text-xs text-gray-300 py-2">
                        {property.propertyFeatures
                          .slice(0, 2)
                          .map(({ name }, key) => {
                            return <span key={key}>{name}</span>
                          })}
                      </div>
                    </div>
                    <div className="font-bold flex items-center justify-between">
                      <div className="flex justify-between flex-1">
                        <div>
                          <span className="text-gray-400">Low Season</span>
                          <div className="flex-col space-x-2 items-center">
                            <h3 className="text-4xl">
                              €{numberWithCommas(property.lowPrice || 0)}
                            </h3>
                            <span className="text-gray-400 ">Per Night</span>
                          </div>
                        </div>
                        <div>
                          <span className="text-gray-400 ">High Season</span>
                          <div className="flex-col space-x-2 items-center">
                            <h3 className="text-4xl">
                              €{numberWithCommas(property.highPrice || 0)}
                            </h3>
                            <span className="text-gray-400">Per Night</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
